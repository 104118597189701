<template>
  <router-link class="light-btn pointer ml-3" :class="{new: homeworks.count_news > 0}" :to="{ name: 'StudentHomeWorks' }">
    <HomeSvg class="icon"/>
    <b-badge v-if="homeworks.count_news" class="badge">{{ homeworks.count_news }}</b-badge>
  </router-link>
</template>

<script>
const INTERVAL_UPDATE = 100;
import HomeSvg from '@/assets/svg/home3.svg'
import {StudentPageService} from "@/services/api.service";
export default {
  name: 'HomeworkMenuIcon',
  components: {
    HomeSvg,
  },
  data() {
    return {
      homeworks: {
        count_news: 0,
      },
    }
  },
  mounted() {
    this.check();

    setInterval(() => {
      this.check();
    }, 1000 * INTERVAL_UPDATE)
  },
  methods: {
    check() {
      StudentPageService.homeworks().then(res => {
        const homeworks = res.data.data.homeworks ? res.data.data.homeworks : [];

        // Calculate counters
        this.homeworks.count_news = homeworks.reduce((count, homework) => count + (homework.is_new ? 1 : 0), 0);
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.light-btn {
  position: relative;
}
.icon {
  fill: $text-themed;
}
.light-btn.new {
  border: 1px solid #FFBD0E;
  box-shadow: -2px -1px 6px 0px #FFBD0E80, 1px 2px 5px 0px #FFBD0E80;
}
.new .icon {
  fill: $text-warning;
}
.badge {
  background-color: #FF4545;
  border-radius: 100px;
  font-size: 9px;
  line-height: 8px;
  font-weight: 800;
  /* Центрирование по вертикали */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>